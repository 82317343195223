import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

function Svg({ className, style }: Partial<CustomIconComponentProps>) {
  const stroke = "var(--textUuiSlateBlue)"

  return (
    <svg 
      width="100%" 
      height="100%" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M17.9492 12.9955C18.768 12.9955 19.4318 13.6593 19.4318 14.478C19.4318 15.2962 18.7681 15.9602 17.949 15.9605C17.1304 15.9604 16.4667 15.2967 16.4667 14.478C16.4667 13.6593 17.1305 12.9955 17.9492 12.9955Z" stroke={stroke}/>
      <path d="M17.9492 4L17.9492 12.4955" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.9492 16.46L17.9492 20" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.05069 12.9955C6.86945 12.9955 7.5332 13.6593 7.5332 14.478C7.5332 15.2962 6.86953 15.9602 6.05046 15.9605C5.2318 15.9604 4.56817 15.2967 4.56817 14.478C4.56817 13.6593 5.23193 12.9955 6.05069 12.9955Z" stroke={stroke}/>
      <path d="M6.05054 4L6.05054 12.4955" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.05054 16.46L6.05054 20" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9946 11.0045C11.1758 11.0045 10.5121 10.3407 10.5121 9.52199C10.5121 8.70385 11.1758 8.03984 11.9948 8.03947C12.8135 8.03959 13.4771 8.7033 13.4771 9.52199C13.4771 10.3407 12.8134 11.0045 11.9946 11.0045Z" stroke={stroke}/>
      <path d="M11.9948 20L11.9948 11.5045" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9948 7.53995L11.9948 3.99999" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default function SettingsIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={Svg} {...props} />;
}
